import React from 'react';

import { ProductBox } from '../components/boxen';
import Seo from '../components/seo';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const LoginPage = () => (
  <>
    <Seo title="Login" />
    <Paper sx={{padding: '0.5em 0.5em 1em'}}>
      <Typography variant='h4' component='h1'>
        Login
      </Typography>
      <Box mb={3}>
        Our hosted ecommerce systems offer simple, private, and reliable ways to
        improve efficiency and accuracy of your processes without forcing you into
        a cumbersome one-size-fits-all system.
      </Box>

      <Box display='flex' flexWrap='wrap' justifyContent='center'>
        <ProductBox maintitle='Wbench'
          href="https://wbench.wernerdigital.com"
          linktitle='wbench'
        >
          <Typography variant='caption'>
            New hosted version of mtools
          </Typography>

        </ProductBox>

        <ProductBox maintitle='Ceres'
          href="https://ceres.wernerdigital.com"
          linktitle='ceres'
        >
          <Typography variant='caption'>
            Ecom marketplace management for sellers
          </Typography>
        </ProductBox>

        <ProductBox maintitle='Legacy/ Custom'
          href="https://prod.io4.wernerdigital.com"
          linktitle='io4'
        >
          <Typography variant='caption'>
            Amazon marketplace integrations
          </Typography>
        </ProductBox>

      </Box>
      <Card className="grad" sx={{marginTop: '1em'}}>
        <Typography px={1} variant='h5' component='h2'>
          Announcements
        </Typography>
      </Card>

    </Paper>
  </>
)

export default LoginPage
